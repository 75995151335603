// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
   padding-bottom: 30px;
   padding-bottom: 40px;
   background: rgba(255, 255, 255, 0.9);
   box-shadow: 14px 14px 45px rgba(0, 0, 0, 0.2);
   border-radius: 10px;
   padding: 40px;
}

.card__img {
   max-width: 300px;
   max-height: 300px;
   margin-inline: auto;
   margin-block: 30px;

   img {
      width: 100%;
      height: 100%;
      object-fit: contain;
   }
}

@media (min-width: 720px) {
   .card {
      padding: 40px 50px;
   }

   .card__img {
      max-width: 400px;
      max-height: 400px;
   }
}

@media (min-width: 992px) {
   .card {
      padding: 40px 70px;
   }

   .card__img {
      max-width: 500px;
      max-height: 500px;
   }
}
`, "",{"version":3,"sources":["webpack://./src/components/CardComponent/CardComponent.css"],"names":[],"mappings":"AAAA;GACG,oBAAoB;GACpB,oBAAoB;GACpB,oCAAoC;GACpC,6CAA6C;GAC7C,mBAAmB;GACnB,aAAa;AAChB;;AAEA;GACG,gBAAgB;GAChB,iBAAiB;GACjB,mBAAmB;GACnB,kBAAkB;;GAElB;MACG,WAAW;MACX,YAAY;MACZ,mBAAmB;GACtB;AACH;;AAEA;GACG;MACG,kBAAkB;GACrB;;GAEA;MACG,gBAAgB;MAChB,iBAAiB;GACpB;AACH;;AAEA;GACG;MACG,kBAAkB;GACrB;;GAEA;MACG,gBAAgB;MAChB,iBAAiB;GACpB;AACH","sourcesContent":[".card {\n   padding-bottom: 30px;\n   padding-bottom: 40px;\n   background: rgba(255, 255, 255, 0.9);\n   box-shadow: 14px 14px 45px rgba(0, 0, 0, 0.2);\n   border-radius: 10px;\n   padding: 40px;\n}\n\n.card__img {\n   max-width: 300px;\n   max-height: 300px;\n   margin-inline: auto;\n   margin-block: 30px;\n\n   img {\n      width: 100%;\n      height: 100%;\n      object-fit: contain;\n   }\n}\n\n@media (min-width: 720px) {\n   .card {\n      padding: 40px 50px;\n   }\n\n   .card__img {\n      max-width: 400px;\n      max-height: 400px;\n   }\n}\n\n@media (min-width: 992px) {\n   .card {\n      padding: 40px 70px;\n   }\n\n   .card__img {\n      max-width: 500px;\n      max-height: 500px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
