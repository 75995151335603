// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PriceListBlock {
   width: 100%;
}

.PriceListComponent__table {
   border-collapse: collapse;
   width: 100%;

   td {
      padding-left: 25px;
   }

   td {
      padding-block: 10px;
   }

   tr {
      &:nth-child(odd) {
         background-color: #ffffff; /* фон нечетных строк */
      }

      &:nth-child(even) {
         background-color: #f9f9f9; /* фон четных строк */
      }

      &:not(:last-child) {
         border-bottom: 1px solid #ddd;
      }
   }
}

.OfertaComponent {
   background: linear-gradient(89.89deg, #4c89c1 0%, #9fa6e8 100%);
   color: #ffffff;
   padding: 15px 50px;
   border-radius: 10px;
   text-align: center;
}

.OfertaComponent__inner {
   font-family: Roboto;
   font-style: normal;
   font-weight: 300;
   font-size: 18px;
   line-height: 27px;
   letter-spacing: 0.05em;
}

@media (min-width: 992px) {
   .OfertaComponent {
      min-width: 700px;
   }
}
`, "",{"version":3,"sources":["webpack://./src/components/PriceListComponent/PriceListComponent.css"],"names":[],"mappings":"AAAA;GACG,WAAW;AACd;;AAEA;GACG,yBAAyB;GACzB,WAAW;;GAEX;MACG,kBAAkB;GACrB;;GAEA;MACG,mBAAmB;GACtB;;GAEA;MACG;SACG,yBAAyB,EAAE,uBAAuB;MACrD;;MAEA;SACG,yBAAyB,EAAE,qBAAqB;MACnD;;MAEA;SACG,6BAA6B;MAChC;GACH;AACH;;AAEA;GACG,+DAA+D;GAC/D,cAAc;GACd,kBAAkB;GAClB,mBAAmB;GACnB,kBAAkB;AACrB;;AAEA;GACG,mBAAmB;GACnB,kBAAkB;GAClB,gBAAgB;GAChB,eAAe;GACf,iBAAiB;GACjB,sBAAsB;AACzB;;AAEA;GACG;MACG,gBAAgB;GACnB;AACH","sourcesContent":[".PriceListBlock {\n   width: 100%;\n}\n\n.PriceListComponent__table {\n   border-collapse: collapse;\n   width: 100%;\n\n   td {\n      padding-left: 25px;\n   }\n\n   td {\n      padding-block: 10px;\n   }\n\n   tr {\n      &:nth-child(odd) {\n         background-color: #ffffff; /* фон нечетных строк */\n      }\n\n      &:nth-child(even) {\n         background-color: #f9f9f9; /* фон четных строк */\n      }\n\n      &:not(:last-child) {\n         border-bottom: 1px solid #ddd;\n      }\n   }\n}\n\n.OfertaComponent {\n   background: linear-gradient(89.89deg, #4c89c1 0%, #9fa6e8 100%);\n   color: #ffffff;\n   padding: 15px 50px;\n   border-radius: 10px;\n   text-align: center;\n}\n\n.OfertaComponent__inner {\n   font-family: Roboto;\n   font-style: normal;\n   font-weight: 300;\n   font-size: 18px;\n   line-height: 27px;\n   letter-spacing: 0.05em;\n}\n\n@media (min-width: 992px) {\n   .OfertaComponent {\n      min-width: 700px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
