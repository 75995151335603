// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sliderComponent {
   overflow: hidden;
}

.sectionComponent__block_carousel {
   max-height: 45rem;
}

.sliderComponent__block {
   height: 100%;
   max-height: 45rem;
   position: initial;
}

.each-slide {
   max-height: 40rem;
   height: 30vw;
   position: initial;
}

.each-slide-img {
   height: 100%;
   margin-top: -5px;
   background-position: center top;
   background-size: 100% 100%;
   background-repeat: no-repeat;
   position: initial;
   z-index: -1;
}

.indicator {
   cursor: pointer;
   padding: 7px;
   border-radius: 50px;
   margin-left: 5px;
   margin-right: 5px;
   margin-top: 0px;
   text-align: center;
   background-color: rgb(218, 218, 218);
}

.indicator.active {
   color: #fff;
   background: rgb(155, 155, 155);
}

@media (min-width: 992px) {
   .sectionComponent__block_carousel {
      margin-inline: -40px;
   }
}

@media (max-width: 992px) {
   .sliderComponent {
      display: none;
   }
}

@media (min-width: 1360px) {
   .sectionComponent__block_carousel {
      margin-inline: calc((100vw - 1300px) / -2);
   }
}
`, "",{"version":3,"sources":["webpack://./src/components/SliderComponent/SliderComponent.css"],"names":[],"mappings":"AAAA;GACG,gBAAgB;AACnB;;AAEA;GACG,iBAAiB;AACpB;;AAEA;GACG,YAAY;GACZ,iBAAiB;GACjB,iBAAiB;AACpB;;AAEA;GACG,iBAAiB;GACjB,YAAY;GACZ,iBAAiB;AACpB;;AAEA;GACG,YAAY;GACZ,gBAAgB;GAChB,+BAA+B;GAC/B,0BAA0B;GAC1B,4BAA4B;GAC5B,iBAAiB;GACjB,WAAW;AACd;;AAEA;GACG,eAAe;GACf,YAAY;GACZ,mBAAmB;GACnB,gBAAgB;GAChB,iBAAiB;GACjB,eAAe;GACf,kBAAkB;GAClB,oCAAoC;AACvC;;AAEA;GACG,WAAW;GACX,8BAA8B;AACjC;;AAEA;GACG;MACG,oBAAoB;GACvB;AACH;;AAEA;GACG;MACG,aAAa;GAChB;AACH;;AAEA;GACG;MACG,0CAA0C;GAC7C;AACH","sourcesContent":[".sliderComponent {\n   overflow: hidden;\n}\n\n.sectionComponent__block_carousel {\n   max-height: 45rem;\n}\n\n.sliderComponent__block {\n   height: 100%;\n   max-height: 45rem;\n   position: initial;\n}\n\n.each-slide {\n   max-height: 40rem;\n   height: 30vw;\n   position: initial;\n}\n\n.each-slide-img {\n   height: 100%;\n   margin-top: -5px;\n   background-position: center top;\n   background-size: 100% 100%;\n   background-repeat: no-repeat;\n   position: initial;\n   z-index: -1;\n}\n\n.indicator {\n   cursor: pointer;\n   padding: 7px;\n   border-radius: 50px;\n   margin-left: 5px;\n   margin-right: 5px;\n   margin-top: 0px;\n   text-align: center;\n   background-color: rgb(218, 218, 218);\n}\n\n.indicator.active {\n   color: #fff;\n   background: rgb(155, 155, 155);\n}\n\n@media (min-width: 992px) {\n   .sectionComponent__block_carousel {\n      margin-inline: -40px;\n   }\n}\n\n@media (max-width: 992px) {\n   .sliderComponent {\n      display: none;\n   }\n}\n\n@media (min-width: 1360px) {\n   .sectionComponent__block_carousel {\n      margin-inline: calc((100vw - 1300px) / -2);\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
