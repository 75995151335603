// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoLicencePage__picture {
    max-width: 300px;
    float: left;
}

.InfoLicencePage__picture img {
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/InformationPage/InfoLicencePage/InfoLicencePage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":[".InfoLicencePage__picture {\n    max-width: 300px;\n    float: left;\n}\n\n.InfoLicencePage__picture img {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
