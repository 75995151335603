// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerBlock {
    background: #eaeaea;
    min-height: 74px;
    padding-bottom: 10px;
}

.footerContainer {
    max-width: 1160px;
    margin: 0 auto;
    
}

.footerContainer__inside {
    margin: 0 20px;
}

.footerContainer__statistic {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 49px;
}

.footerContainer_info {
    font-weight: 400;
    font-size: 12px;
    line-height: 115.4%;
    letter-spacing: 0.055em;
}

.footerContainer_statImage {
    margin-right: 7px;
}`, "",{"version":3,"sources":["webpack://./src/components/FooterComponent/FooterComponent.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,cAAc;;AAElB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".footerBlock {\n    background: #eaeaea;\n    min-height: 74px;\n    padding-bottom: 10px;\n}\n\n.footerContainer {\n    max-width: 1160px;\n    margin: 0 auto;\n    \n}\n\n.footerContainer__inside {\n    margin: 0 20px;\n}\n\n.footerContainer__statistic {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    height: 49px;\n}\n\n.footerContainer_info {\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 115.4%;\n    letter-spacing: 0.055em;\n}\n\n.footerContainer_statImage {\n    margin-right: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
