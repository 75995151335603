// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infoMapPageButton__ul {
    margin-left: 40px;
}

.infoMapPageButton {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    color: #3d3d3d;
    font-size: 16px;
    padding: 7px;
    align-items: left;
    cursor: pointer;
    background-color: transparent;
}

.infoMapPageButton__h3 {
    text-align: left;
}

.infoMapPageButton:hover {
    color: #005bb1;
    font-weight: 500;
    /* transform: scale(1.01); */
}`, "",{"version":3,"sources":["webpack://./src/pages/InformationPage/InfoMapPage/InfoMapPage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,4BAA4B;AAChC","sourcesContent":[".infoMapPageButton__ul {\n    margin-left: 40px;\n}\n\n.infoMapPageButton {\n    font-family: \"Roboto\";\n    font-style: normal;\n    font-weight: 400;\n    color: #3d3d3d;\n    font-size: 16px;\n    padding: 7px;\n    align-items: left;\n    cursor: pointer;\n    background-color: transparent;\n}\n\n.infoMapPageButton__h3 {\n    text-align: left;\n}\n\n.infoMapPageButton:hover {\n    color: #005bb1;\n    font-weight: 500;\n    /* transform: scale(1.01); */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
