// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoLegFramPage__picture {
    max-width: 300px;
    float: left;
    margin: 10px;
}

.InfoLegFramPage__picture img {
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/InformationPage/InfoLegFramPage/InfoLegFramPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".InfoLegFramPage__picture {\n    max-width: 300px;\n    float: left;\n    margin: 10px;\n}\n\n.InfoLegFramPage__picture img {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
