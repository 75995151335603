// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/pages-bg.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../img/img-bg-5-4.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-page__container {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 30px;
}

.service-page,
.InfoQualificationsPage,
.InfoRegulationsPage,
.InfoMapPage {
   background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
   background-repeat: no-repeat;
   background-size: 100%;
   padding-bottom: 120px;
}

.newsPageComponent,
.ContactsPageComponent,
.InfoAboutPage,
.InfoVacanciesPage,
.InfoControlOrgPage,
.InfoLicencePage,
.InfoVacanciesPage,
.DmsPage,
.InfoOffDocumentsPage,
.InfoLegFramPage,
.specific_new_page {
   background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
   background-repeat: no-repeat;
   background-size: 100%;
   padding-bottom: 120px;
}

@media (min-width: 720px) {
   .service-page__container {
      gap: 50px;
   }
}

@media (min-width: 992px) {
   .service-page,
   .InfoQualificationsPage,
   .InfoRegulationsPage,
   .InfoMapPage {
      min-height: 1000px;
      background-size: 100% 1050px;
   }

   .newsPageComponent,
   .ContactsPageComponent,
   .InfoAboutPage,
   .InfoVacanciesPage,
   .InfoControlOrgPage,
   .InfoLicencePage,
   .InfoVacanciesPage,
   .DmsPage,
   .InfoOffDocumentsPage,
   .InfoLegFramPage,
   .specific_new_page {
      min-height: 900px;
      background-size: 100% 950px;
   }

   .service-page__container {
      gap: 70px;
   }
}
`, "",{"version":3,"sources":["webpack://./src/pages/PagesBackgroundStyles.css"],"names":[],"mappings":"AAAA;GACG,aAAa;GACb,sBAAsB;GACtB,mBAAmB;GACnB,SAAS;AACZ;;AAEA;;;;GAIG,yDAA4C;GAC5C,4BAA4B;GAC5B,qBAAqB;GACrB,qBAAqB;AACxB;;AAEA;;;;;;;;;;;GAWG,yDAA8C;GAC9C,4BAA4B;GAC5B,qBAAqB;GACrB,qBAAqB;AACxB;;AAEA;GACG;MACG,SAAS;GACZ;AACH;;AAEA;GACG;;;;MAIG,kBAAkB;MAClB,4BAA4B;GAC/B;;GAEA;;;;;;;;;;;MAWG,iBAAiB;MACjB,2BAA2B;GAC9B;;GAEA;MACG,SAAS;GACZ;AACH","sourcesContent":[".service-page__container {\n   display: flex;\n   flex-direction: column;\n   align-items: center;\n   gap: 30px;\n}\n\n.service-page,\n.InfoQualificationsPage,\n.InfoRegulationsPage,\n.InfoMapPage {\n   background-image: url(\"../img/pages-bg.png\");\n   background-repeat: no-repeat;\n   background-size: 100%;\n   padding-bottom: 120px;\n}\n\n.newsPageComponent,\n.ContactsPageComponent,\n.InfoAboutPage,\n.InfoVacanciesPage,\n.InfoControlOrgPage,\n.InfoLicencePage,\n.InfoVacanciesPage,\n.DmsPage,\n.InfoOffDocumentsPage,\n.InfoLegFramPage,\n.specific_new_page {\n   background-image: url(\"../img/img-bg-5-4.png\");\n   background-repeat: no-repeat;\n   background-size: 100%;\n   padding-bottom: 120px;\n}\n\n@media (min-width: 720px) {\n   .service-page__container {\n      gap: 50px;\n   }\n}\n\n@media (min-width: 992px) {\n   .service-page,\n   .InfoQualificationsPage,\n   .InfoRegulationsPage,\n   .InfoMapPage {\n      min-height: 1000px;\n      background-size: 100% 1050px;\n   }\n\n   .newsPageComponent,\n   .ContactsPageComponent,\n   .InfoAboutPage,\n   .InfoVacanciesPage,\n   .InfoControlOrgPage,\n   .InfoLicencePage,\n   .InfoVacanciesPage,\n   .DmsPage,\n   .InfoOffDocumentsPage,\n   .InfoLegFramPage,\n   .specific_new_page {\n      min-height: 900px;\n      background-size: 100% 950px;\n   }\n\n   .service-page__container {\n      gap: 70px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
