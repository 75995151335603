import React from "react";
import "./PriceListComponent.css";

export const OfertaComponent = () => {
   return (
      <div className="OfertaComponent">
         <div className="OfertaComponent__inner">
            Прайс-лист не является публичной офертой.
            На сайте ведутся работы по изменению цен на лабораторные исследования.
         </div>
      </div>
   );
};
