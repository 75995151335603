// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoControlOrgPage__info {
    margin: 20px 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.05em;
    font-size: 18px;

    color: #2c2c2c;
}

.InfoControlOrgPage__info_header {
    margin-bottom: 15px;
    color: #006bcf;
}

.InfoControlOrgPage__info_desc {
    font-size: 16px;
}

.controlOrgInformation__hr_visible {
    border-top: 1px solid #d8d8d8;
    width: 100%;
}

`, "",{"version":3,"sources":["webpack://./src/pages/InformationPage/InfoControlOrgPage/InfoControlOrgPage.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,eAAe;;IAEf,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,WAAW;AACf","sourcesContent":[".InfoControlOrgPage__info {\n    margin: 20px 0;\n    font-family: \"Roboto\";\n    font-style: normal;\n    font-weight: 400;\n    line-height: 27px;\n    letter-spacing: 0.05em;\n    font-size: 18px;\n\n    color: #2c2c2c;\n}\n\n.InfoControlOrgPage__info_header {\n    margin-bottom: 15px;\n    color: #006bcf;\n}\n\n.InfoControlOrgPage__info_desc {\n    font-size: 16px;\n}\n\n.controlOrgInformation__hr_visible {\n    border-top: 1px solid #d8d8d8;\n    width: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
