// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewPage__text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  font-size: 16px;
  margin: 7px 0;

  color: #2c2c2c;
}

.NewPage_list {
    padding-left: 20px;
    margin: 20px 0;
}

.text_bold {
    font-weight: 600;
}

ul.NewPage_list li {
    margin: 5px 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/NewsPage/NewsPages/NewStyles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;EACf,aAAa;;EAEb,cAAc;AAChB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".NewPage__text {\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  line-height: 27px;\n  font-size: 16px;\n  margin: 7px 0;\n\n  color: #2c2c2c;\n}\n\n.NewPage_list {\n    padding-left: 20px;\n    margin: 20px 0;\n}\n\n.text_bold {\n    font-weight: 600;\n}\n\nul.NewPage_list li {\n    margin: 5px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
