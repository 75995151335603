// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.servisesContainer {
    font-family: "Roboto";
    font-style: normal;
    line-height: 21px;
    letter-spacing: 0.05em;
    padding: 10px 0px 10px 0px;
    color: #424242;
    
}

.servisesHeading{
    color: #3d3d3d;
    font-family: "Roboto";
    font-weight: bolder;
    padding: 10px 0px 10px 0px;
}

.servisesText {
    padding: 10px 10px 10px 0px;
    font-weight: 400;
    font-size: 16px;
    color: #3d3d3d;
}`, "",{"version":3,"sources":["webpack://./src/pages/InformationPage/InfoRegulationsPage/InfoRegulationsPage.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;IACtB,0BAA0B;IAC1B,cAAc;;AAElB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".servisesContainer {\n    font-family: \"Roboto\";\n    font-style: normal;\n    line-height: 21px;\n    letter-spacing: 0.05em;\n    padding: 10px 0px 10px 0px;\n    color: #424242;\n    \n}\n\n.servisesHeading{\n    color: #3d3d3d;\n    font-family: \"Roboto\";\n    font-weight: bolder;\n    padding: 10px 0px 10px 0px;\n}\n\n.servisesText {\n    padding: 10px 10px 10px 0px;\n    font-weight: 400;\n    font-size: 16px;\n    color: #3d3d3d;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
