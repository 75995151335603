// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServicesSpecialistsComponent_header {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.05em;

    color: #3281c9;
}

.ServicesSpecialistsComponent__ul {
    margin: 15px 50px;
}

.ServicesSpecialistsComponent__li {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.05em;

    color: #494949;
}
`, "",{"version":3,"sources":["webpack://./src/components/ServicesSpecialistsComponent/ServicesSpecialistsComponent.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;;IAEtB,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;;IAEtB,cAAc;AAClB","sourcesContent":[".ServicesSpecialistsComponent_header {\n    font-family: \"Roboto\";\n    font-style: normal;\n    font-weight: normal;\n    font-size: 24px;\n    line-height: 28px;\n    letter-spacing: 0.05em;\n\n    color: #3281c9;\n}\n\n.ServicesSpecialistsComponent__ul {\n    margin: 15px 50px;\n}\n\n.ServicesSpecialistsComponent__li {\n    font-family: \"Roboto\";\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 27px;\n    letter-spacing: 0.05em;\n\n    color: #494949;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
