// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuButtons {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 575px;
    border-width: 10px;
    padding: 0px 0px;
}

.menuButton {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    color: #FFFFFF;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    cursor: pointer;
    background-color: transparent;
    transition: .1s all ease-in;
}

.menuButton__expandArrow {
    padding-left: 6px;
}

.menuButton:hover {
    color: rgb(53, 53, 53);
    background-color: rgba(255, 255, 255, 0.288);
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.514);
}`, "",{"version":3,"sources":["webpack://./src/components/FullHeaderComponent/MenuButtonsComponent/MenuButtonsComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,6BAA6B;IAC7B,2BAA2B;AAC/B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,4CAA4C;IAC5C,sDAAsD;AAC1D","sourcesContent":[".menuButtons {\n    display: grid;\n    grid-template-columns: repeat(6, 1fr);\n    width: 575px;\n    border-width: 10px;\n    padding: 0px 0px;\n}\n\n.menuButton {\n    font-family: \"Roboto\";\n    font-style: normal;\n    font-weight: 300;\n    color: #FFFFFF;\n    font-size: 18px;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 20px 10px;\n    cursor: pointer;\n    background-color: transparent;\n    transition: .1s all ease-in;\n}\n\n.menuButton__expandArrow {\n    padding-left: 6px;\n}\n\n.menuButton:hover {\n    color: rgb(53, 53, 53);\n    background-color: rgba(255, 255, 255, 0.288);\n    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.514);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
