import aseeva from "assets/images/doctors/aseeva.jpg";
import bezverhiy from "assets/images/doctors/bezverhiy.jpg";
import berdnikovEA from "assets/images/doctors/berdnikovEA.jpg";
import biryukova from "assets/images/doctors/biryukova.jpg";
import bondarenkoSU from "assets/images/doctors/bondarenkoSU.jpg";
import chupryna from "assets/images/doctors/chupryna.jpg";
import dorofeevAA from "assets/images/doctors/dorofeevAA.jpg";
import filippova from "assets/images/doctors/filippova.jpg";
import grigorieva from "assets/images/doctors/grigorieva.jpg";
import gigolaevaAP from "assets/images/doctors/gigolaevaAP.jpg"
import inozemtsevAV from "assets/images/doctors/inozemtsevAV.jpg";
import isaeva from "assets/images/doctors/isaeva.jpg";
import kharitonov from "assets/images/doctors/kharitonov.jpg";
import kislova from "assets/images/doctors/kislova.jpg";
import litvinenko from "assets/images/doctors/litvinenko.jpg";
import matrosov from "assets/images/doctors/matrosov.jpg";
import medvedevaEV from "assets/images/doctors/medvedevaEV.jpg";
import nazarova from "assets/images/doctors/nazarova.jpg";
import naumenko from "assets/images/doctors/naumenko.jpg";
import noginaNV from "assets/images/doctors/noginaNV.jpg";
import petruhina from "assets/images/doctors/petruhina.jpg";
import pleshakovAV from "assets/images/doctors/pleshakovAV.jpg";
import pochivalovaEE from "assets/images/doctors/pochivalovaEE.jpg";
import ryabinin from "assets/images/doctors/ryabinin.jpg";
import shaposhnikova from "assets/images/doctors/shaposhnikova.jpg";
import sherbinina from "assets/images/doctors/sherbinina.jpg";
import simonovaMG from "assets/images/doctors/simonovaMG.jpg";
import sizovaIV from "assets/images/doctors/sizovaIV.jpg";
import subbotina from "assets/images/doctors/subbotina.jpg";
import tvorogova from "assets/images/doctors/tvorogova.jpg";
import melnik from "assets/images/doctors/melnik.jpg";

export const doctors = [
   {
      image: aseeva,
      name: "Асеева Татьяна Александровна",
      speciality:
         "Врач функциональной диагностики, врач ультразвуковой диагностики",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["20 лет; 5 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградская медицинская академия (Специальность: «Лечебное дело» (2000г.);",
               "- Интернатура по специальности: «Терапия» (2001г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- «С-первичная специализация по функциональной диагностики» в ВМА (2002г.);",
               "- Повышение квалификации по программе «Клинической электрокардиографии» (216ч.) в ВолГМУ (13.10.2012г.);",
               "- Повышение квалификации по программе «Ультразвуковая диагностика заболевания суставов» (72 ч.) в ФГБОУ ДПО «Институт повышения квалификация Федерального медикобиологического агентства» (07.03.2015г.);",
               "- Повышение квалификации по программе «Клиническая электрокардиография» (144ч.) в ВолгГМУ (30.10.2017 г.);",
               "- Повышение квалификации по программе «Ультразвуковое исследование сосудов (цветное дуплексное сканирование артериальной и венозной систем)» (108 ч.) в ФГБОУ ДПО «Институт повышения квалификация Федерального медикобиоло-гического агентства» (25.11.2017г.);",
               "- Повышение квалификации по программе «Клиническая электрокардиография» (216 ч.) в ВолгГМУ (2012 г.);",
               "- Повышение квалификации по программе: «Функциональная диагностика» в ООО «МЕДТРЕЙН» (144ч.) (15.10.2021 г.);",
               "- Профессиональная переподготовка по программе: «Ультразвуковая диагностика» в АНО ДПО «Европейский Университет «Бизнес Треугольник» г.Санкт-Петербург (576ч.) (15.06.2018г.);",
               "- Повышение квалификации по программе: «Ультразвуковая диагностика» в ЧОУ ДПО «Академия делового развития» (144 ч.) (20.12.2021г.).",
            ],
         },
      ],
   },
   {
      image: "",
      name: "Банкина Айман Галимовна",
      speciality: "Врач-физиотерапевт",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["15 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградская медицинская академия (специальность: «Лечебное дело» 1998г.);",
               "- Интернатура по специальности: «Пульмонология» (1998г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Профессиональная переподготовка по программе: «Физиотерапия» в ГБОУВПО «ВолГМУ» (30.06.2016г.);",
               "- Повышение квалификации по программе: «Физиотерапия в неврологии» в ФГБОУВО «ВолГМУ» г.Волгоград (54ч.) (06.04.2017г.);",
               "- Повышение квалификации по программе: «Физиотерапия в неврологии» в ФГБОУВО «ВолГМУ» г.Волгоград (54ч.) (26.03.2018г.);",
               "- Повышение квалификации по программе: «Физиотерапия» в ЧОУ ДПО «Региональная академия делового образования» (144ч.) (28.03.2020г.);",
               "- Повышение квалификации по программе: «Физиотерапия» в ЧОУ ДПО «Региональная академия делового образования» (144ч.) (25.03.2023г.).",
            ],
         },
      ],
   },
   {
      image: bezverhiy,
      name: "Безверхий Алексей Владимирович",
      speciality: "Врач-онколог, врач ультразвуковой диагностики",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["24 года; 2 года"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Астраханский медицинский институт им. А.В. Луначарского (Специальность: «Лечебное дело» (1987г.);",
               "- Интернатура по специальности «Авиационная и космическая медицина» (1989г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Переквалификация по программе: «Онкология» (2001г.);",
               "– Повышение квалификации по программе: «Онкология» (144 ч.) в ГБОУ ДПО «Российская академия последипломного образования» (08.02.2016 г.);",
               "– Повышение квалификации по программе: «Основы маммологии» (36 ч.) в ВолгГМУ (25.02.2017 г.);",
               "- Профессиональная переподготовка по специальности «Ультразвуковая диагностика» (576ч.) в ФГБОУВО «ВолГМУ» (27.06.2020г.).",
            ],
         },
      ],
   },
   {
      image: berdnikovEA,
      name: "Бердников Евгений Александрович",
      speciality: "Врач ультразвуковой диагностики",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["3 года"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградская медицинская академия (специальность: «Педиатрия» 1999г.);",
               "- Интернатура по специальности «Детская хирургия» (1999г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Профессиональная переподготовка «Ультразвуковая диагностика» в ЧУ «ООДПО «МАЭиО» г.Саратов (30.11.2019г.).",
            ],
         },
      ],
   },
   {
      image: biryukova,
      name: "Бирюкова Ирина Александровна",
      speciality: "Врач-невролог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["28 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградский медицинский институт (Специальность: «Педиатрия» (1993г.);",
               "- Интернатура по специальности: «Нервные болезни» (1994г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "– Повышение квалификации по программе: «Актуальные вопросы неврологии» (144 ч.) в ВолгГМУ (08.05.2013 г.);",
               "– Повышение квалификации по программе: «Актуальные вопросы неврологии» (144 ч.) в ВолгГМУ (09.03.2018г.);",
               "– Повышение квалификации по программе: «Острые и хронические болевые синдромы. Междисциплинарный подход в лечении и диагностике» (36ч.) в ВолгГМУ (24.05.2019г.);",
               "– Повышение квалификации по программе: «Нейродегенеративные заболевания и психосоматика» (36ч.) в ВолгГМУ (25.04.2020г.);",
               "– Повышение квалификации по программе: «Современные аспекты решения проблем пациентов с сосудистой патологией головного мозга» (36ч.) в ВолгГМУ (27.03.2021г.);",
               "– Повышение квалификации по программе: «Неврологические осложнения новой коронавирусной инфекции» (36ч.) в ВолгГМУ (29.05.2022г.).",
            ],
         },
      ],
   },
   {
      image: gigolaevaAP,
      name: "Гиголаева Алла Павловна",
      speciality: "Врач-невролог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["28 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Курский государственный медицинский институт (1995г.);",
               '- Клиническая интернатура по специальности: "Неврология"(1996г.)',
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Повышение квалификации по программе: «Локальная терапия боли» в ВолГМУ (18 ч.) (2014 г.);",
               "- Повышение квалификации по программе: « Актуальные вопросы неврологии» в ВолГМУ (144 ч.) (2016г.);",
               "- Повышение квалификации по программе: «Болевые синдромы в практике невролога» в ВолГМУ (36 ч.) (2016 г.);",
               "- Повышение квалификации по программе: «Болевые синдромы в практике невролога» в ВолГМУ (36 ч.) (2017 г.);",
               "- Повышение квалификации по программе: «Неврология» в ФГБОУВО «ПИМУ» (144 ч.) (2020 г.);",
               "- Повышение квалификации по программе: «Болезнь Паркинсона и расстройство движения» в ЧОУ ДПО «РАДО» (36 ч.) (2021 г.);",
               "- Повышение квалификации по программе: «Болевые синдромы в неврологии» в ФГБОУВО «ПИМУ» (36 ч.) (2021 г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы диагностики и лечения демиелинизирующих заболеваний центральной нервной системы» в ЧОУ ДПО «РАДО» (36 ч.) (2022г.);",
               "- Повышение квалификации по программе: «Хронические прогрессирующие заболевания нервной системы» в ЧОУ ДПО «РАДО» (36 ч.) (2023 г.);",
            ],
         },
      ],
   },
   {
      image: grigorieva,
      name: "Григорьева Лариса Вячеславовна",
      speciality: "Врач-хирург",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["32 года"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградский медицинский институт (Специальность: «Лечебное дело (1988г.);",
               "- Интернатура по специальности: «Хирургия» (1989г.);",
               "- Ординатура по специальности «Хирургия» (1994г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Повышение квалификации по программе: «Неотложная хирургия органов брюшной полости» в ВолГМУ (144 ч.) (2011 г.);",
               "- Повышение квалификации по программе: «Хирургия» в ВолГМУ (144ч.) (2016 г.);",
               "- Повышение квалификации по программе: «Плазмолифтинг в ортопедии и травматологии» (8 часов) (18.10.2017г.);",
               "- Повышение квалификации по программе: «Хирургия» в ООО «Хорс-Групп» (144ч.) (24.04.2020г.).",
            ],
         },
      ],
   },
   {
      image: dorofeevAA,
      name: "Дорофеев Алексей Александрович",
      speciality: "Врач-невролог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["27 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградская медицинская академия (Специальность: «Лечебное дело» (1994г.);",
               "- Интернатура по специальности: «Неврология» (1995г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Повышение квалификации по программе: «Неврология» (144ч.) (26.12.2016г.);",
               "- Повышение квалификации по программе: «Неврология» (36ч.) (21.04.2017г.);",
               "- Повышение квалификации по программе: «Неврология» (36ч.) (20.04.2018г.);",
               "- Повышение квалификации по программе: «Неврология» (36ч.) (22.03.2019г.);",
               "- Повышение квалификации по программе: «Неврология» (144ч.) (30.04.2021г.);",
               "- Повышение квалификации по программе: «Неврология» (36ч.) (25.04.2020г.).",
            ],
         },
      ],
   },
   {
      image: isaeva,
      name: "Исаева Наталья Станиславовна",
      speciality: "Врач-гастроэнтеролог, врач-ревматолог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["21 год; 1 год"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградская медицинская академия (Специальность: «Лечебное дело» (1998г.);",
               "- Интернатура по специальности: «Терапия» (1999г.);",
               "- Клиническая ординатура по специальности: «Терапия» (2001 г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Профессиональная переподготовка по программе: «Первичная специализация по гастроэнтерологии» (27.06.2005 г.);",
               "- Повышение квалификации по программе: «Терапия с актуальными вопросами гастроэнтерологии» (144ч.) (30.04.2015 г.);",
               "- Профессиональная переподготовка по направлению: «Организация здравоохранения и общественного здоровья» от 11.02.2019 г.;",
               "- Повышение квалификации: «Экспертиза временной нетрудоспосб-ности» от 30.09.2019 г. (72ч.);",
               "- Повышение квалификации: «Гастроэнтерология» (144ч.) в ООО «Институт инновационных технологий» (04.03.2020г.);",
               "- Повышение квалификации по программе «Клиническая фармакология в гастроэнтерологии и ревматологии» (36ч.) АНО ДПО «СИНМО» (04.05.2021г.);",
               "- Повышение квалификации по программе: «Современные аспекты в организации здравоохранения и общественного здоровья» АНО ДПО «УПОР» (2022г.);",
               "- Профессиональная переподготовка по программе: «Ревматология» АНО ДПО «СИНМО» (576ч.) (27.01.2021г.).",
            ],
         },
      ],
   },
   {
      image: inozemtsevAV,
      name: "Иноземцев Алексей Владимирович",
      speciality: "Врач-дерматовенеролог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["22 года"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградский медицинская академия (Специальность: «Лечебное дело» (1997г.);",
               "- Интернатура (Специальность: «дерматовенерология» (1998 г.);",
               "- Клиническая ординатура (Специальность: «дерматовенерология» (2000г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Повышение квалификации по программе: «Актуальный вопросы дерматовенерологии» в ВолгМУ (17.03.2003г.);",
               "- Повышение квалификации по программе: «Актуальный вопросы дерматовенерологии» в ВолГМУ (144ч.) (22.10.2008г.);",
               "- Повышение квалификации по программе: «Актуальный вопросы дерматовенерологии» в ВолгМУ (29.10.2013г.);",
               "- Повышение квалификации по программе: «Дерматовенерология» в ФГБОУВО «ВГМУ» (144ч.) (09.06.2018г.);",
               "- Повышение квалификации по программе: «Дерматовенерология» в АНО ДПО «Академия успеха» (144ч.) (15.05.2023г.).",
            ],
         },
      ],
   },
   {
      image: kislova,
      name: "Кислова Светлана Александровна",
      speciality: "Врач-акушер-гинеколог; Врач ультразвуковой диагностики",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["16 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- ГОУВПО «Волгоградский государственный медицинский университет» (специальность: «Лечебное дело» 2007г.);",
               '- Интернатура "Акушерство и гинекология" (2008);',
               "- Ординатура «Акушерство и гинекология» (2019г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               '- Повышение квалификации по программе: "Основы диагностической и лечебной кольпоскопии" в ГОУВПО СПб ГМА им.И.И.Мечникова (72ч.) (2009);',
               '- Повышение квалификации по программе: "Репродуктивная медицина" в ГБОУ ВПО КубГМУ Минздрава России (72ч.) (2012г.);',
               '- Повышение квалификации по программе: "Гинекология детей и подростков" в ПМГМУ им.Сеченова (108ч.) (18.11.2014г);',
               '- Повышение квалификации по программе: "Неотложные состояния в акушерстве и гинекологии" в ФГБОУВО "ВолгГМУ" (144ч.) (29.09.2018г.);',
               '- Повышение квалификации по программе: "Акушерство и гинекология" в ЧУ ДПО "ИПиПКСЗ" (144ч.) (11.10.2023г.);',
               "- Профессиональная переподготовка по программе: «Ультразвуковая диагностика» в ВолгГМУ (506ч.) (30.01.2010г.);",
               '- Повышение квалификации по программе: "Основы пренатального скрининга в 11-14 недель беремменности. Расширенная эхокардиография плода" (72ч.) в ФГБОУ ДПО "ИПК" (26.12.2025г.);',
               '- Повышение квалификации по программе: "Ультразвуковая диагностика" в ЧУДПО "ИПиПКСЗ" (144ч.) (25.12.2020г.).',
            ],
         },
      ],
   },
   {
      image: litvinenko,
      name: "Литвиненко Мадина Геннадьевна",
      speciality: "Врач ультразвуковой диагностики",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["16 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Таджикский государственный медицинский университет им.Абдуали ибн-Сино (специальность: «Педиатрия») (1994г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Профессиональная переподготовка «Ультразвуковая диагностика» (579ч.) в ВолгМА (19.01.2001г.);",
               "- Повышение квалификации по программе: «Ультразвуковая диагностика заболеваний и повреждений крупных суставов» в ФГУ ЦИТО им.Н.Н. Пирогова (72 ч.) (17.06.2011г.);",
               "- Повышение квалификации по программе: «Ультразвуковая диагностика» в ИППО ФМБУ им.А.И.Бурназяна (144 ч.) (10.12.2011г.);",
               "- Повышение квалификации по программе: «Ультразвуковая диагностика» в ФГБОУ ДПО «ИПК ФМБА» (144 ч.) (29.10.2016г.);",
               "- Повышение квалификации по программе: «Основы пренатального скрининга в 11-14 недель беременности» в ФГБОУ ДПО «ИПК ФМБА» (36 ч.) (27.05.2017г.);",
               "- Повышение квалификации по программе: «Ультразвуковая диагностика в гинекологии» в ФГБОУ ВО «ВГМУ» (54 ч.) (31.03.2018г.);",
               "- Повышение квалификации по программе: «Ультразвуковая диагностика заболеваний брахиоцефальных сосудов» в ФГБОУ ВО «ВГМУ» (54 ч.) (20.04.2019г.);",
               "- Повышение квалификации по программе: «Ультразвуковая мамография» в ФГБОУ ВО «ВГМУ» (54 ч.) (25.05.2020г.).",
            ],
         },
      ],
   },
   {
      image: matrosov,
      name: "Матросов Владимир Геннадьевич",
      speciality: "Врач-колопроктолог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["34 года"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградский государственный медицинский институт (Специальность: «Лечебное дело» (1986г.);",
               "- Клиническая ординатура по специальности «Хирургия» (1997 г.) в ВМА.",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Повышение квалификации по программе: «Неотложная хирургия органов брюшной полости» (144 ч.) в ФГБОУВО «ВолГМУ» (26.12.2013г.);",
               "- Повышение квалификации по программе: «Колопроктология» (144ч.) в ФГБОУВО «ВолГМУ» (04.04.2016 г.);",
               "- Повышение квалификации по программе: «Заболевания прямой кишки и тазового дна у женщин (общемедицинские и хирургические аспекты)» (36ч.) в ФГБОУВО «ВолГМУ» (04.04.2016г.);",
               "- Повышение квалификации по программе: «Хирургия» (144 ч.) в ФГБОУВО «ВолГМУ» (29.10.2018 г.).",
            ],
         },
      ],
   },
   {
      image: melnik,
      name: "Мельникова Ольга Андреевна",
      speciality: "Врач-дераматовенеролог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["7 лет."],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградский государственный медицинский университет (Специальность: «Лечебное дело» (2011г.);",
               "- Интернатура по специальности: «Дерматовенерология» (2013г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Повышение квалификации по программе: «Дерматовенерология» в ВолгГМУ(144ч.) (09.06.2018г.);",
               "- Мастер-класс по программе: «Plasmolifting Geltherapy в косметологии» (08 ч.) (2017 г.);",
               "- Мастер-класс по программе: «Plasmolifting в косметологии и трихологии» (08 ч.) (2017 г.);",
               "- Повышение квалификации по программе: «Дерматоонкология, основы дерматоскопии» в АНО ДПО «ДДМ» (36ч.) (23.11.2020г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы профилактики, диагностики и лечение новой коронавирусной инфекции (КОВИД19)» в ООО «ПРОФАУДИТ-КОНСАЛТ» (36ч.) (20.08.2021г.).",
            ],
         },
      ],
   },
   {
      image: medvedevaEV,
      name: "Медведева Елена Владимировна",
      speciality: "Врач ультразвуковой диагностики",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["16 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Ташкенский ордена Трудового Красного Знамени медицинский институт (по специальности «Лечебное дело»)(1986г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Первич.специализация по ультразвуковой диагностике (576ч.) ВолГМУ (2006г.);",
               "- Повышение квалификации по программе: «Пренатальная (дородовая) диагностика нарушений развития ребенка» в ФГБОУ ДПО «РМАПО» (72 ч.) (22.10.2016г.);",
               "- Повышение квалификации по программе: «Ультразвуковая диагностика» в ФГБОУ ВО «ВГМУ» (144 ч.) (29.04.2017г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы ультразвуковой диагностики» в ЧУ «ООДПО «Международная академия экспертизы и оценки» (144 ч.) (30.12.2020г.).",
            ],
         },
      ],
   },
   {
      image: "",
      name: "Меркушова (Павлова) Анна Эдуардовна",
      speciality: "Врач ультразвуковой диагностики",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["21 год"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградская медицинская академия (Специальность: «Педиатрия» (2000г.);",
               "- Интернатура по специальности: «Фтизиопульмонология» (2001 г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Профессиональная переподготовка по программе «Ультразвуковая диагностика» в Российская медицинская академия (2001г.);",
               "- Повышение квалификации по программе «Ультразвуковая диагностика заболеваний органов брюшной полости, малого таза и поверностно расположенных органов» в ФГУ «Учебно-научном медицинском центре» (144ч.) (08.04.2006г.);",
               "- Повышение квалификации по программе «Ультразвуковая диагностика» в ГОУ ДПО «РМАПОР» (144ч.) (04.02.2008г.);",
               "- Повышение квалификации по программе «Ультразвуковая диагностика» в ГОУВПО СПбГМА им.И.И.Мечникова (144ч.) (10.03.2011г.);",
               "– Повышение квалификации по программе: «Актуальные вопросы ультразвуковой диагностики по международным стандартам» (144 ч.) в ООО «ЭКСПРОМЕД» (11.03.2016г.);",
               "- Профессиональная переподготовка по программе: «Организация здравоохранения и общественное здоровье» в АНО ДПО «ЕЦПК» (576ч.) (25.12.2019г.)",
               "- Повышение квалификации по программе: «Избранные вопросы ультразвуковой диагностики» (150 ч.);",
               "ЧУ ДПО «Центр дополнительного медицинского образования» от 22.06.2020г.",
            ],
         },
      ],
   },
   {
      image: nazarova,
      name: "Назарова Нина Владимировна",
      speciality:
         "Врач аллерголог-иммунолог (кандидат медицинских наук, 1988г.)",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["24 года"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградский государственный медицинский институт (Специальность: «Педиатрия» (1981г.);",
               "- Клиническая ординатура по специальности «Педиатрия» (1983г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Профессиональная переподготовка по программе «Аллергология и иммунология» в Иркутском институте усовершенствования врачей (288ч.) (28.12.1989г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы аллергологии и иммунологии» в ВолгГМУ (144ч.) (07.03.2013г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы аллергологии и иммунологии» в ВолгГМУ (144ч.) (06.03.2018 г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы аллергологии и иммунологии» в ВолгГМУ (36ч.) (27.10.2018 г.);",
               "- Повышение квалификации по программе: «Аллергология и иммунология» в ФГБОУ ДПО «РМАНПО» (36ч.) (02.05.2020г.);",
               "- Повышение квалификации по программе: «Неотложные состояния в аллергологии» в ВолгГМУ (36ч.) (20.03.2021г.);",
               "- Повышение квалификации по программе: «Функциональная диагностика в аллергологии» в ВолгГМУ (36ч.) (28.06.2021г.);",
               "- Повышение квалификации по программе: «Современные аспекты АСИТ в терапии аллергологических заболеваний» в ВолгГМУ (36ч.) (24.12.2022г.).",
            ],
         },
      ],
   },
   {
      image: naumenko,
      name: "Науменко Антон Евгеньевич",
      speciality: "Врач-уролог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["6 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградский государственный медицинский университет (Специальность: «Педиатрия» (2016г.);",
               "- Ординатура по специальности: «Урология» (2016 г.).",
            ],
         },
      ],
   },
   {
      image: noginaNV,
      name: "Ногина Наталья Вячеславовна",
      speciality: "Врач-невролог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["20 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградская медицинская академия (Специальность: «Лечебное дело» (2001г.);",
               "- Интернатура по специальности: «Неврология» (2002 г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Повышение квалификации по программе: « Актуальные вопросы неврологии» в ВолгГМУ (144 ч.) (28.02.2013г.);",
               "- Повышение квалификации по программе: «Локальная терапия боли» в ВолгГМУ(18ч.)(15.02.2014г.);",
               "- Повышение квалификации по программе: «Нейрореабилитация при церебрально-васкулярной патологии» в ВолгГМУ (18 ч.) (19.12.2015 г.);",
               "- Повышение квалификации по программе: «Формирование здорового образа жизни» в ВолгГМУ (72 ч.) (27.06.2015 г.);",
               "- Повышение квалификации по программе: «Нейрореабилитация при церебрально-васкулярной патологии» в ВолгГМУ (18 ч.) (25.06.2016г.);",
               "- Повышение квалификации по программе: «Локальная терапия боли. III ступень» в ВолгГМУ (36 ч.) (22.09.2018 г.);",
               "- Повышение квалификации по программе: «Экспертиза временной нетрудоспособ-ности» в ЧУ ДПО «ИПиПКСЗ» (72 ч.) (17.04.2018 г.);",
               "- Повышение квалификации по программе: «Неврология» в ЧУ ДПО «ИПиПКСЗ» (144 ч.) (16.04.2018 г.);",
               "- Повышение квалификации по программе: «Эпилепсия, генетика, диагностика, лечение» в ФГБОУВО «КМУим.В.Ф. Войно-Ясенецкого» г.Красноярск (36ч.) (15.11.2021г.);",
               "- Повышение квалификации по программе: «Сосудистые когнитивные нарушения. Алгоритм диагностики и лечения» в ФГАОУВО «РНИМУим.Пирогова» г.Москва (18 ч.) (16.09.2022г.);",
               "- Повышение квалификации по программе: «Функциональное артезирование» в АПО ФГБУ «ФНКЦСВМПиМТ ФМБА» г.Москва (36ч.) (19.02.2022г.);",
               "- Повышение квалификации по программе: «Нарушение когнитивных функций в неврологии» в АНО ДПО «ЕЦПК» г.Санкт-Петербург (36ч.) (26.12.2022г.);",
               "- Повышение квалификации по программе: «Головные боли – вопросы диагностики, лечения и профилактики» в АНО ДПО «ЦПК и ПП» г.Москва (36 ч.) (30.01.2023г.).",
            ],
         },
      ],
   },
   {
      image: petruhina,
      name: "Петрухина Галина Геннадиевна",
      speciality: "Врач-оториноларинголог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["26 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградская медицинская академия (Специальность: «Педиатрия» (1995г.);",
               "- Интернатура по специальности: «Оториноларингология» (1996г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Повышение квалификации по программе: «Экспертиза временной и стойкой нетрудоспособности; экспертиза качества медицинской помощи » (72 ч.) в ВолГМУ (15.03.2007г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы оториноларинго-логии» в ВолгГМУ (144 ч.) (14.11.2014 г.);",
               "- Повышение квалификации по программе: «Избранные вопросы оториноларинго-логии» в ЧУД ПО «Центр дополнительного медицинского образования» (150 ч.) (06.05.2019 г.);",
               "- Повышение квалификации по программе: «Диагностическая эндоскопия в оториноларингологии» АНО ДПО «Меди-цинский университет инновации и развития» (36 ч.) (28.10.2020г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы заболеваний ЛОР органов» ООО «Межрегиональный Центр Переподготовки Медицинских Кадров» (36ч.) (06.06.2023г.).",
            ],
         },
      ],
   },
   {
      image: "",
      name: "Полякова Людмила Александровна",
      speciality: "Врач-уролог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["43 года"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградский государственный медицинский институт (1978 г.);",
               "- Интернатура по специальности: «Хирургия» (1979 г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Первичная специализация по специальности «Урология» (1985 г.);",
               "- Повышение квалификации по специальности «Урология» (2012 г.);",
               "- Повышение квалификации по программе: «Современные вопросы урологии» (144 ч.) (03.04.2017 г.).",
            ],
         },
      ],
   },
   {
      image: "",
      name: "Полякова Олеся Сергеевна",
      speciality: "Врач-оториноларинголог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["6 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградский государственный медицинский университет (2014 г.);",
               "- Интернатура по специальности: «Оториноларингология» (2016 г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Повышение квалификации по программе: «Актуальные вопросы заболеваний глотки» в ВолГМУ (36ч.) (16.06.2017г.);",
               "- Повышение квалификации по программе: «Профпатология с вопросами экспертизы профпригодности» ООО «Межотраслевой институт госаттестации» (144ч.) (30.03.2020г.).",
            ],
         },
      ],
   },
   {
      image: ryabinin,
      name: "Рябинин Олег Анатольевич",
      speciality: "Врач-невролог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["22 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградская медицинская академия (2001г.);",
               '- Интернатура по специальности: "Педиатрия" (2003г.).',
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Повышение квалификации по программе: «Неврология» в УМЦ ГУНИМ “Неврологии” РАМН» (500ч.) (29.11.2002г.);",
               "- Повышение квалификации по программе: “Неврология” в ООО “Вдохновляй» Москва (144ч.) (16.09.2022г.)",
            ],
         },
      ],
   },
   {
      image: pochivalovaEE,
      name: "Почивалова Екатерина Евгеньевна",
      speciality: "Врач-эндокринолог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["6 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградский государственный медицинский университет (2015г.);",
               "- Интернатура по специальности: «Эндокринология» (2016 г.).",
            ],
         },
      ],
   },
   {
      image: sizovaIV,
      name: "Сизова Ирина Валентиновна",
      speciality: "Врач ультразвуковой диагностики, врач-акушер-гинеколог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["10 лет; 23 года"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Кубанская государственная междицинская академия (специальность: «Лечебное дело» 1998г.);",
               "- Интернатура «Акушерство и гинекология» (1999г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Профессиональная переподготовка «Ультразвуковая диагностика» в ВолГМУ (576ч.) (15.02.2011г.);",
               "- Повышение квалификации «Ультразвуковая зиагностика» в ГБОУВПО «ВолГМУ» (20.05.2016г.) (216ч.);",
               "- Повышение квалификации «Эндокринология в акушерстве и гинекологии» в ГБОУВПО «ВолГМУ» (30.10.2013г.) (144ч.);",
               "- Повышение квалификации «Неотложные состояния в акушерстве и гинекологии» в ГБОУВПО «ВолГМУ» (02.06.2015г.) (144ч.);",
               "- Повышение квалификации «Акушерство и гинекология» в ГБОУВПО «ВолГМУ» (05.10.2020г.) (144ч.).",
            ],
         },
      ],
   },
   {
      image: simonovaMG,
      name: "Симонова Марина Геннадьевна",
      speciality: "Врач функциональной диагностики",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["25 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградский государственный медицинский институт (1994г.);",
               "- Интернатура «Терапия» (1995г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Профессиональная переподготовка по программе «Функциональная переподготовка» (432ч.) в ВолгМА (20.12.1997г.);",
               "- Повышение квалификации по программе в ВолгГМУ: «Функциональная диагностика в кардиологии» (216 ч.) (23.05.2015г.);",
               "- Повышение квалификации по программе в ВолгГМУ: «Функциональная диагностика в кардиологии» (144 ч.) (28.04.2020г.);",
               "- Повышение квалификации по программе в ВолгГМУ: «Функциональная диагностика в кардиологии» (144 ч.) (01.07.2022г.).",
            ],
         },
      ],
   },
   {
      image: subbotina,
      name: "Субботина Людмила Николаевна",
      speciality: "Врач-терапевт",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["23 года"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградская медицинская академия (Специальность: «Лечебное дело» (1995г.);",
               "- Интернатура по специальности: «Терапия» (1996г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Повышение квалификации по программе: «Терапия» в ВолгМА (06.03.2000г.);",
               "- Повышение квалификации по программе: «Актуальные проблемы клинической фармакология в клинике внутренних болезней» в ВолгГМУ (30.04.2005г.);",
               "- Профессиональная переподготовка по программе: «Кардиология» в ВолгГМУ (2012 г.);",
               "- Повышение квалификации по программе: «Клиническая кардиология» в ВолгГМУ (216 ч.) (2013 г.);",
               "- Профессиональная переподготовка по программе: «Вакцинопрофилактика» в ФБУЗ «Центр гигиены и эпидемиологии в Волгоградской области» (2013г.);",
               "- Повышение квалификации по программе: «Клиническая электрокардиография» в ВолгГМУ (144ч.) (2017г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы терапии» в ВолгГМУ (144ч.) (2015 г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы терапии» в ВолгГМУ (144 ч.) (05.03.2020 г.).",
            ],
         },
      ],
   },
   {
      image: tvorogova,
      name: "Творогова Лариса Георгиевна",
      speciality: "Врач-терапевт",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["22 года"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградская медицинская академия (Специальность: «Лечебное дело» (1999г.);",
               "- Интернатура по специальности: «Терапия» (2000 г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Повышение квалификации по программе: « Актуальные вопросы лечения заболеваний сердца» в ВолгМА (14.12.2001г.);",
               "- Повышение квалификации по программе: « Вопросы терапии» в ВолгГМУ (29.10.2005г.);",
               "- Повышение квалификации по программе: « Вопросы терапии с актуальными вопросами кардиологии» в ВолгГМУ (144ч.) (24.12.2011 г.);",
               "- Повышение квалификации по программе: «Клиническая фармакология в клинике внутренних болезней» в ВолгГМУ (144ч.) (06.02.2013 г.);",
               "- Профессиональная переподготовка по профессиональной деятельности в сфере «Общей врачебной практики» в ВолгГМУ (26.12.2014г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы профпатологии» в ФГБУ ГНЦ ФМБЦ им. А.И. Бурназяна ФМБА России» (72ч.) (27.12.2014 ч.);",
               "- Повышение квалификации по программе: «Артериальная гипертония» в  ФГБОУВО «ВолГМУ» (36ч.) (16.03.2019г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы профпатологии» в ФГБУ ГНЦ ФМБЦ России» (72ч.) (26.06.2019 г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы предупреждения и ликвидации медико-санитарных последствий чрезвычайных ситуаций химического характера» в  АПО ФГБУ «ФНКЦСВМПиМТ ФМБА» (72ч.) (12.11.2019г.);",
               "- Повышение квалификации по программе: «Общая врачебная практика» в ООО «Современные образовательные технологии» (144ч.) (30.03.2020г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы профилактики, диагностики и лечения коронавирусной инфекции КИВИД-19» в ФГБОУ ДПО «РМАНПО» (36ч.) (03.07.2020г.);",
               "- Повышение квалификации по программе: «Обеспечение здоровья пожилых людей в условиях эпидемии и пандемии» в  АПО ФГБУ «ФНКЦСВМПиМТ ФМБА» (24ч.) (07.11.2020г.);",
               "- Повышение квалификации по программе: «Правила и порядок осуществления деятельности, связанной с оборотом наркотических средств, психотропных веществ и их прекурсоров» в ООО «Современные образовательные технологии» (72ч.) (03.11.2021г.);",
               "- Повышение квалификации по программе: «Основы иммунопрофилактики, вакцинопрофилактика» в ООО «Столичный центр эффективных образовательных технологий» (72ч.) (03.11.2021г.);",
               "- Повышение квалификации по программе: «Современные принципы диагностики и лечения злокачественных новообразований» в ФГБОУВО «ВолГМУ» (144ч.) (26.02.2022г.);",
               "- Повышение квалификации по программе: «Онконастороженность. Ранняя диагностика онкологических заболеваний в практике врача первичного звена» в АПО ФГБУ «ФНКЦСВМПиМТ ФМБА»(18ч.) (20.05.2022г.);",
               "- Повышение квалификации по программе: «Актуальные вопросы эпидемиологии и профилактики инфекций, связанных с оказанием медико-санитарной помощи (ИСМП)» в  ООО МУЦ ДПО «Образовательный стандарт» (36ч.) (08.11.2022г.).",
            ],
         },
      ],
   },
   {
      image: "",
      name: "Титовская Недежда Александровна ",
      speciality: "Врач-кардиолог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["22 года"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградская медицинская академия (Специальность: «Лечебное дело» (1999г.);",
               "- Интернатура по специальности: «Терапия» (2000 г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Первичная специализация «Кардиология» (01.04.2002г.) в ВолгМА;",
               "- Повышение квалификации по программе «Клиническая кардиология» в ВолГМУ (31.05.2005г.);",
               "- Повышение квалификации по программе «Избранные вопросы кардиологии» в ВолГМУ (15.04.2006г.);",
               "- Повышение квалификации «Функциональня диагностика в кардиологии» (144ч.) в ФГБОУВО «ВолгГМУ» (28.12.2019г);",
               "- Повышение квалификации по программе: «Экспертиза временной и стойкой нетрудоспособности. Экспертиза качества медицинской помощи» (72 ч.) (04.04.2020г.) в ФГБОУВО «ВолгГМУ»;",
               "- Повышение квалификации по программе «Кардиология» (144ч.) в ЧУДПО «ИПиПКСЗ» (02.04.2020г.).",
            ],
         },
      ],
   },
   {
      image: filippova,
      name: "Филиппова Юлия Борисовна",
      speciality: "Врач-эндокринолог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["25 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Российский государственный медицинский университет (специальность: «Лечебное дело») 1994г;",
               "- Клиническая ординатура по специальности: «Эндокринология» 1996г.",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Повышение квалификации по программе: «Эндокринология» (144ч.) в ФГБОУ ДПО «РМАНПО» (16.03.2017г.);",
               "- Повышение квалификации по программе: «Эндокринология» (144ч.) в НОЧУ ДПО «Высшая медицинская школа» (07.12.2020г.);",
               "- Повышение квалификации по программе: «Экспертиза временной нетрудоспособности» (72ч.) в ЧУ ДПО «ИПиПКСЗ» (21.06.2021г.);",
               "- Повышение квалификации по программе: «Эпидемиология и профилактика инфекций, связанных с оказанием медицинской помощи» (36ч.) в ЧУ ДПО «ИПиПКСЗ» (07.02.2022г.).",
            ],
         },
      ],
   },
   {
      image: kharitonov,
      name: "Харитонов Борис Александрович",
      speciality: "Врач-кардиолог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["5 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               '- ГОУВПО Волгоградский государственный медицинский университет Федеральногоагенствапо здравоохранению и социальному развитию" (специальность: "Педиатрия" 2007г.);',
               '- Интернатура по специальности: "Скорая медицинская помощь" 2009г.',
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               '- Профессиональная переподготовка по специальности "Кардиология" в ФГБОУВО "ВолгГМУ" (18.09.2019г.);',
               '- Повышение квалификации по программе: "Амбулаторная кардиология" в ООО "МЕДТРЕЙН" (144ч.) (15.10.2021г.);',
               '- Повышение квалификации по программе: "Практические вопросы клинической трансфузиологии" в ФГБОУВО "ВолгГМУ" (18ч.) (17.11.2023г.)',
            ],
         },
      ],
   },
   {
      image: chupryna,
      name: "Чупрына Петр Сергеевич",
      speciality: "Врач-уролог",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["23 года"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградская медицинская академия (Специальность: «Лечебное дело» (1998г.);",
               "- Интернатура: Волгоградская медицинская академия (Специальность: «Хирургия» (1999г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Первичная специализация по специальности «Урология» (2000) Ростовский гос.мед. университет;",
               "- Профессиональная переподготовка по специальности: «Урология» в ГУ «Ростовский государствнный медицинский университет» (576ч.) (31.05.2000г.);",
               "- Повышение квалификации по программе: «Эндоурология» (216ч.) в ВолГМУ (14.06.2013г.);",
               "- Профессиональная переподготовка по специальности «Ультразвуковая диагностика» (576ч.) (12.05.2017г.) ФГБОУВО «ВГМУ»;",
               "- Повышение квалификации по программе: «Урогинекология» (144ч.) в ВолГМУ (30.09.2019г.).",
            ],
         },
      ],
   },
   {
      image: shaposhnikova,
      name: "Шапошникова Наталья Владимировна",
      speciality: "Врач-невролог, врач функциональной диагностики",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["24 года; 14 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградская медицинская академия (Специальность: «Педиатрия» (1998г.);",
               "- Интернатура: Волгоградская медицинская академия (Специальность: «Педиатрия» (1999 г.).",
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Краткосрочное повышение квалификации: «С-первичная специализация по неврологии» (1999 г.) в ВМА;",
               "- Профессиональная переподготовка по специальности: «Первичная специализация по функциональной диагностике» в ВолгГМУ (2009г.);",
               "- Профессиональная переподготовка по специальности: «Ультразвуковая диагностика; в ВолгГМУ (2006г.);",
               "- Профессиональная переподготовка: «Организация здравоохранения и общественного здоровья» (10.06.2017 г.);",
               "- Повышение квалификации по программе: «Неотложная педиатрия» в ВолгГМУ (144ч.) (2018 г.);",
               "- Повышение квалификации по программе: «Обеспечение экологической безопасности при обращении с медицински-ми отходами» в Академии Безопасности (18.06.2019г.);",
               "- Повышение квалификации: по программе: «Избранные вопросы функциональной диагностики» в ЧУ ДПО «Центр дополнительного медицинского образования» (2015 г.);",
               "- Повышение квалификации по программе: «Детская неврология» в АНО ДПО «Центр дистанционного образования» г.Нижневартовск (144ч.) (28.12.2022г.);",
               "- Повышение квалификации по программе: «Организация здравоохранения и общественное здоровье» в ООО «МИПК» (144ч.) (06.04.2022г.);",
               "- Повышение квалификации по программе «Экспертиза временной нетрудоспособности и контроль качества медицинской помощи» в ООО «МИПК» (144ч.)(04.04.2023г.);",
               "- Профессиональная переподготовка по специальности: «Первичная специализация по функциональной диагностике» в ВолгГМУ (06.05.2009г.);",
               "- Профессиональная переподготовка по специальности: «Ультразвуковая диагностика; в ВолгГМУ (2006г.);",
               "- Повышение квалификации: по программе: «Избранные вопросы функциональной диагностики» в ЧУ ДПО «Центр дополнительного медицинского образования» (2015 г.);",
               "- Повышение квалификации: по программе: «Избранные вопросы функциональной диагностики» (150ч.) в ЧУ ДПО «Центр дополнительного медицинского образования» (25.05.2020г.);",
               "- повышение квалификации по программе «Ультразвуковая ангилогия по международным стандартам» ООО «МЕДТРЕЙН» (192ч.) 15.04.2022г.",
            ],
         },
      ],
   },
   {
      image: sherbinina,
      name: "Щербинина Татьяна Валентиновна",
      speciality: "Врач-терапевт; Врач ультразвуковой диагностики",
      doctorInfo: [
         {
            header: "Стаж работы по врачебной специальности:",
            description: ["39 лет"],
         },
         {
            header: "ВУЗ, где выдан документ о первичной специализации:",
            description: [
               "- Волгоградский государственный медицинский институт по специальности: «Лечебное дело» (1984г.);",
               '- Интернатура по специальности: "Терапия" (1986г.).',
            ],
         },
         {
            header: "Курсы повышения квалификации/Переквалификация",
            description: [
               "- Повышение квалификации по программе: «Терапия» в ООО «МрЦАМК» (144ч.) (29.06.2023г.);",
               "- Повышение квалификации по программе: «Ультразвуковая диагностика» в ЧУДПО «ИПиПКСЗ» (144ч.) (14.02.2019г.);",
               '- Повышение квалификации по программе: "Ультразвуковая диагностика" в ЧУДПО "ИПиПКСЗ" (144ч.) (05.03.2024г.).',
            ],
         },
      ],
   },
];
