import React from "react";
import { InformationContainerComponent } from "../../../components/InformationContainerComponent/InformationContainerComponent";

export const New3 = () => {
  return (
    <div className="specific_new_page">
      <InformationContainerComponent header="Назначения ВИМС">
        <div className="NewPage__text">
          <div className="NewPage__text">
          Назначения ВИМС:  
          </div>
          <a href="https://docs.google.com/document/d/1Pu48V912GglgMJXrOqryVEkWT0Bxs_34/edit?usp=sharing&ouid=112631188905352530754&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer">
            Скачать документ
          </a>
        </div>
      </InformationContainerComponent>
    </div>
  );
};
