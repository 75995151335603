// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuButtonPhone {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  color: #484848;
  font-size: 18px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 30px;
  cursor: pointer;
  background-color: transparent;
}

.menuButton__expandArrow {
  padding-left: 6px;
}

.menuButtonPhone:hover {
  background-color: #f5f5f5;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/components/FullHeaderComponent/HeaderComponent/MenuButtonsPhoneComponent/MenuButtonsPhoneComponent.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":[".menuButtonPhone {\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 300;\n  color: #484848;\n  font-size: 18px;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  align-items: center;\n  padding: 16px 30px;\n  cursor: pointer;\n  background-color: transparent;\n}\n\n.menuButton__expandArrow {\n  padding-left: 6px;\n}\n\n.menuButtonPhone:hover {\n  background-color: #f5f5f5;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
