// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoQualificationsPage__table {
  border-collapse: collapse;
  width: 100%;
}

.InfoQualificationsPage__thead {
  font-weight: 400;
  font-size: 18px;
}

.InfoQualificationsPage__thead_td {
  padding-left: 20px;
  padding-bottom: 5px;
}

.InfoQualificationsPage__trow_td {
  border-top: 1px solid #ddd;
  padding: 10px 10px;
  margin-right: 20px;
}

.name {
  padding-right: 20px;
}

tbody tr:nth-child(odd) {
  background-color: #ffffff; /* фон нечетных строк */
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* фон четных строк */
}

@media (max-width: 720px) {
  .overrrrrrrr {
    overflow-x: scroll;
    width: 100%;
  }

  .InfoQualificationsPage {
    min-width: 0;
  }

  .InfoQualificationsPage__thead {
    font-size: 12px;
  }

  .InfoQualificationsPage__table {
    width: 100%;
    font-size: 12px;
  }

  .InfoQualificationsPage__trow_td {
    padding: 5px 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/InformationPage/InfoQualificationsPage/InfoQualificationsPage.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB,EAAE,uBAAuB;AACpD;;AAEA;EACE,yBAAyB,EAAE,qBAAqB;AAClD;;AAEA;EACE;IACE,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".InfoQualificationsPage__table {\n  border-collapse: collapse;\n  width: 100%;\n}\n\n.InfoQualificationsPage__thead {\n  font-weight: 400;\n  font-size: 18px;\n}\n\n.InfoQualificationsPage__thead_td {\n  padding-left: 20px;\n  padding-bottom: 5px;\n}\n\n.InfoQualificationsPage__trow_td {\n  border-top: 1px solid #ddd;\n  padding: 10px 10px;\n  margin-right: 20px;\n}\n\n.name {\n  padding-right: 20px;\n}\n\ntbody tr:nth-child(odd) {\n  background-color: #ffffff; /* фон нечетных строк */\n}\n\ntbody tr:nth-child(even) {\n  background-color: #f9f9f9; /* фон четных строк */\n}\n\n@media (max-width: 720px) {\n  .overrrrrrrr {\n    overflow-x: scroll;\n    width: 100%;\n  }\n\n  .InfoQualificationsPage {\n    min-width: 0;\n  }\n\n  .InfoQualificationsPage__thead {\n    font-size: 12px;\n  }\n\n  .InfoQualificationsPage__table {\n    width: 100%;\n    font-size: 12px;\n  }\n\n  .InfoQualificationsPage__trow_td {\n    padding: 5px 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
