// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UnderHeaderMenuComponent {
  background-color: #6385db;
  position: sticky;
  top: var(--h-header-lg);
  z-index: 5000;
}

.menuButtonsAll {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin: 0 auto;
}

.menuButtonsAll__makeAnAppointmentButton {
  width: 191px;
  justify-self: end;
  cursor: pointer;
  background-color: transparent;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  color: #ffffff;
}

@media (max-width: 992px) {
  .UnderHeaderMenuComponent {
    display: none;
  }
}

@media (max-width: 720px) {
  .UnderHeaderMenuComponent__inner {
    margin-left: 10px;
    margin-right: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/FullHeaderComponent/UnderHeaderMenuComponent/UnderHeaderMenuComponent.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,6BAA6B;EAC7B,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,kBAAkB;EACpB;AACF","sourcesContent":[".UnderHeaderMenuComponent {\n  background-color: #6385db;\n  position: sticky;\n  top: var(--h-header-lg);\n  z-index: 5000;\n}\n\n.menuButtonsAll {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 2rem;\n  margin: 0 auto;\n}\n\n.menuButtonsAll__makeAnAppointmentButton {\n  width: 191px;\n  justify-self: end;\n  cursor: pointer;\n  background-color: transparent;\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 300;\n  color: #ffffff;\n}\n\n@media (max-width: 992px) {\n  .UnderHeaderMenuComponent {\n    display: none;\n  }\n}\n\n@media (max-width: 720px) {\n  .UnderHeaderMenuComponent__inner {\n    margin-left: 10px;\n    margin-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
