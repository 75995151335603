// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactsPageComponent__container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.InfoContactPage__info {
  margin: 20px 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.05em;
  font-size: 18px;

  color: #2c2c2c;
}

.ContactsPageComponent__info {
  margin-right: 20px;
}

.ContactsPageComponent__map {
  width: 50%;
}

.InfoContactPage__info_header {
  font-size: 18px;
  margin-bottom: 15px;
  color: #006bcf;
}

.InfoContactPage__info_desc {
  font-size: 16px;
}

.ContactPageInformation__hr_visible {
  border-top: 1px solid #d8d8d8;
  width: 100%;
}

@media (max-width: 992px) {
  .ContactsPageComponent__container {
    flex-direction: column;
  }
  .ContactsPageComponent__map {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ContactsPage/ContactsPage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,eAAe;;EAEf,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".ContactsPageComponent__container {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n}\n\n.InfoContactPage__info {\n  margin: 20px 0;\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  line-height: 27px;\n  letter-spacing: 0.05em;\n  font-size: 18px;\n\n  color: #2c2c2c;\n}\n\n.ContactsPageComponent__info {\n  margin-right: 20px;\n}\n\n.ContactsPageComponent__map {\n  width: 50%;\n}\n\n.InfoContactPage__info_header {\n  font-size: 18px;\n  margin-bottom: 15px;\n  color: #006bcf;\n}\n\n.InfoContactPage__info_desc {\n  font-size: 16px;\n}\n\n.ContactPageInformation__hr_visible {\n  border-top: 1px solid #d8d8d8;\n  width: 100%;\n}\n\n@media (max-width: 992px) {\n  .ContactsPageComponent__container {\n    flex-direction: column;\n  }\n  .ContactsPageComponent__map {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
