// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoAboutPage_text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.05em;

    color: rgb(78, 78, 78);
}
`, "",{"version":3,"sources":["webpack://./src/pages/InformationPage/InfoAboutPage/InfoAboutPage.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;;IAEtB,sBAAsB;AAC1B","sourcesContent":[".InfoAboutPage_text {\n    font-family: \"Roboto\";\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 27px;\n    letter-spacing: 0.05em;\n\n    color: rgb(78, 78, 78);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
