// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoVacanciesPage__header,
.InfoVacanciesPage_list {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    font-size: 16px;
    letter-spacing: 0.05em;

    color: #3d3d3d;
}

.InfoVacanciesPage_list li {
    list-style: inside;
}
`, "",{"version":3,"sources":["webpack://./src/pages/InformationPage/InfoVacanciesPage/InfoVacanciesPage.css"],"names":[],"mappings":"AAAA;;IAEI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,sBAAsB;;IAEtB,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".InfoVacanciesPage__header,\n.InfoVacanciesPage_list {\n    font-family: \"Roboto\";\n    font-style: normal;\n    font-weight: 400;\n    line-height: 27px;\n    font-size: 16px;\n    letter-spacing: 0.05em;\n\n    color: #3d3d3d;\n}\n\n.InfoVacanciesPage_list li {\n    list-style: inside;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
