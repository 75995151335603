// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../usr/local/lib/node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newsPage__new_component {
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 10px;
  background: transparent;
  border-radius: 10px;

  margin: 20px 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.05em;
  font-size: 18px;

  color: #2c2c2c;
}

.newsPage__new_header {
  font-size: 24px;
  margin-bottom: 15px;
  color: #006bcf;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
}

.newsPage__new_description {
  font-size: 16px;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/pages/NewsPage/NewsPage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,oBAAoB;EACpB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,oBAAoB;EACpB,uBAAuB;EACvB,mBAAmB;;EAEnB,cAAc;EACd,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,eAAe;;EAEf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".newsPage__new_component {\n  width: 100%;\n  padding-bottom: 30px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding-bottom: 10px;\n  background: transparent;\n  border-radius: 10px;\n\n  margin: 20px 0;\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  line-height: 27px;\n  letter-spacing: 0.05em;\n  font-size: 18px;\n\n  color: #2c2c2c;\n}\n\n.newsPage__new_header {\n  font-size: 24px;\n  margin-bottom: 15px;\n  color: #006bcf;\n  background-color: transparent;\n  cursor: pointer;\n  text-align: left;\n}\n\n.newsPage__new_description {\n  font-size: 16px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
